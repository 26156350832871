import React, { useState, useEffect } from 'react'

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom'
import Layout from '../component/layout'
import { updateSpinner } from '../../../features/authentication/authenticationReducer';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { importWaterConcentratorsURL } from '../../../utils/urls';


function ViewConcentrator() {
    const [addFacilityVisible, setAddFacilityVisible] = useState(false)
    const [addMeterVisible, setAddMeterVisible] = useState(false)
    const [importMeterVisible,setImportMeterVisible]= useState(false)


    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard"}>Home</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/core/water_management/water_concentrators"}>Concentrators</Link>
                                </li>
                                <li className="breadcrumb-item" aria-current="page">
                                    View Concentrator
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <Link to={"/core/water_management/water_concentrators"} ><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body py-0">
                                                        <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                    <i className="ti ti-info-circle me-2"></i>Info
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                    <i className="ti ti-building-warehouse me-2"></i>Facilities
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                    <i className="ti ti-columns me-2"></i>Meters
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                                    <i className="ti ti-file-text me-2"></i>Network Configuration
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-5" role="tab" aria-selected="true">
                                                                    <i className="ti ti-settings me-2"></i>Settings
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="tab-content">
                                                    <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-xxl-3">
                                                                <div className="card">
                                                                    <div className="card-body position-relative">

                                                                        <div className="text-center mt-3">
                                                                            <div className="chat-avtar d-inline-flex mx-auto">
                                                                                <i className="ti ti-copyright me-2" style={{ fontSize: 100 }}></i>
                                                                            </div>
                                                                            <h5 className="mb-0"></h5>
                                                                            <h2 className="text-muted mt-2"></h2>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div style={{ float: 'right' }}>
                                                                    <Link to={"#"} type="button" className="btn btn-primary float-end" style={{ color: 'white' }} onClick={() => {
                                                                        setAddFacilityVisible(true)
                                                                    }}>
                                                                        <i className="ti ti-plus" />&nbsp;Add</Link>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className='col-md-12'>
                                                                <div className="dt-responsive table-responsive">
                                                                    <DataTable value={[]} emptyMessage="No facilities found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                        <Column field="name" sortable header="Facility Name"></Column>
                                                                        <Column field="" header="Actions"></Column>
                                                                    </DataTable>
                                                                    <br />

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="tab-pane fade" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-3">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div style={{ float: 'right' }}>
                                                                    <Link to={"#"} type="button" className="btn btn-primary float-end" style={{ color: 'white' }} onClick={()=>{
                                                                        setAddMeterVisible(true)
                                                                    }}>
                                                                        <i className="ti ti-plus" />&nbsp;Add</Link>

                                                                    <Link to={"#"} type="button" className="btn btn-primary float-end" style={{ marginRight: 4 }} onClick={()=>{
                                                                        setImportMeterVisible(true)
                                                                    }}>
                                                                        <i className="ti ti-file-import" />
                                                                        &nbsp;
                                                                        Import
                                                                    </Link>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className='col-md-12'>
                                                                <div className="dt-responsive table-responsive">
                                                                    <DataTable value={[]} emptyMessage="No water meters found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                        <Column field="serialNumber" sortable header="Serial Number"></Column>
                                                                        <Column field="manufacturer" header="Manufacturer"></Column>
                                                                        <Column field="size" header="Size"></Column>
                                                                        <Column field="initialValue" header="Initial Value"></Column>
                                                                        <Column field="currentValue" header="Current Value" ></Column>
                                                                        <Column field="status" sortable header="Status" ></Column>
                                                                        <Column field="location" sortable header="Location" ></Column>
                                                                    </DataTable>
                                                                    <br />

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="tab-pane fade" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h6>SIM CARD</h6>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">

                                                                            <div className="col-md-3">
                                                                                <label>Phone Number</label>
                                                                                <input type='tel' className="form-control mt-2"></input>

                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label>Serial Number</label>
                                                                                <input type='text' className="form-control mt-2"></input>

                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h6>APN</h6>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">

                                                                            <div className="col-md-3">
                                                                                <label>URL</label>
                                                                                <input type='url' className="form-control mt-2"></input>

                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label>Username</label>
                                                                                <input type='text' className="form-control mt-2"></input>

                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label>Password</label>
                                                                                <input type='password' className="form-control mt-2"></input>

                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h6>Network</h6>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <label>Ip Address</label>
                                                                                <input type='text' className="form-control mt-2"></input>

                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>PORT</label>
                                                                                <input type='number' className="form-control mt-2"></input>

                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div style={{ float: 'right' }}>
                                                                    <button type="button" className="btn btn-primary">Submit</button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="tab-pane fade" id="profile-5" role="tabpanel" aria-labelledby="profile-tab-5">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h6>Edit</h6>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label>Serial Number</label>
                                                                                <input type='text' className="form-control mt-2"></input>

                                                                            </div>


                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div style={{ float: 'right' }}>
                                                                                    <button type="button" className="btn btn-primary">Submit</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header={`Add a New Facility`} visible={addFacilityVisible} style={{ width: '30vw' }} onHide={() => { if (!addFacilityVisible) return; setAddFacilityVisible(false); }}>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Choose Facility</label>
                        <select className="form-control mt-2">
                            <option>-- Select --</option>
                            <option></option>

                        </select>

                    </div>


                </div>
                <div className="row mt-3">
                    <div className="col-md-12 ">
                        <div style={{ float: 'right' }}>
                            <button className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>




            </Dialog>
            
            <Dialog header={`Add a New Meter`} visible={addMeterVisible} style={{ width: '30vw' }} onHide={() => { if (!addMeterVisible) return; setAddMeterVisible(false); }}>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Choose Unit</label>
                        <select className="form-control mt-2">
                            <option>-- Select --</option>
                            <option></option>

                        </select>

                    </div>


                </div>
                <div className="row mt-3">
                    <div className="col-md-12 ">
                        <div style={{ float: 'right' }}>
                            <button className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>




            </Dialog>
            <Dialog header={`Import Meters`} visible={importMeterVisible} style={{ width: '30vw' }} onHide={() => { if (!importMeterVisible) return; setImportMeterVisible(false); }}>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Upload File</label>
                        <input className="form-control mt-2" type="file"></input>

                    </div>


                </div>
                <div className="row mt-3">
                    <div className="col-md-12 ">
                        <div style={{ float: 'right' }}>
                            <button className="btn btn-primary">Upload</button>
                        </div>
                    </div>
                </div>




            </Dialog>
        </Layout>
    )
}
export default ViewConcentrator