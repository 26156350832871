
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getItem } from '../../../utils/localStorage';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest';
import { addNewUserURL, deleteUserURL, fetchUsersURL, getSMSEmailSettingsURL, updateSMSEmailSettingsURL } from '../../../utils/urls';
function Setting() {
  const [provider, setProvider] = useState('')
  const [userDialog, setUserDialog] = useState(false)
  const [emailFrom, setEmailFrom] = useState('');
  const [emailPort, setEmailPort] = useState('');
  const [emailHost, setEmailHost] = useState('');
  const [emailAuthUser, setEmailAuthUser] = useState('');
  const [emailAuthPass, setEmailAuthPass] = useState('');
  const [senderId1, setSenderId1] = useState('');
  const [username, setUsername] = useState('');
  const [apiKey1, setAPIKey1] = useState('')
  const [senderId2, setSenderId2] = useState('');
  const [apiKey2, setAPIKey2] = useState('')
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('')
  const [users, setUsers] = useState([])
  const [userEmail, setUserEmail] = useState("")



  const fetchSMSEmailSettings = async () => {
    try {
      const response = await makeRequest2(getSMSEmailSettingsURL, 'GET', {})
      if (response.success) {

        if (response.data.email) {
          setEmailFrom(response.data.email.from);
          setEmailPort(response.data.email.port);
          setEmailHost(response.data.email.host);
          setEmailAuthUser(response.data.email.auth.user);
          setEmailAuthPass(response.data.email.auth.pass);
        }
        if (response.data.africastalking) {
          setSenderId1(response.data.africastalking.senderId);
          setUsername(response.data.africastalking.username);
          setAPIKey1(response.data.africastalking.apikey);
        }
        if (response.data.meliora) {
          setSenderId2(response.data.meliora.senderId);
          setAPIKey2(response.data.meliora.apiKey)
        }



      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const fetchUsers = async () => {
    try {
      const response = await makeRequest2(fetchUsersURL, 'GET', {})

      if (response.success) {

        setUsers(response.data)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const HandleUpdateEmailSMSSettings = async () => {
    try {
      const response = await makeRequest2(updateSMSEmailSettingsURL, 'POST', {
        provider,
        emailFrom,
        emailPort,
        emailHost,
        emailAuthUser,
        emailAuthPass,
        senderId1,
        username,
        apiKey1,
        senderId2,
        apiKey2
      })
      if (response.success) {
        toastify('Successfully Updated', 'success');
        await fetchSMSEmailSettings()
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }

  const HandelAddUser = async () => {
    try {
      if (firstName === '') {
        throw new Error('First Name is required')
      }
      else if (lastName === '') {
        throw new Error('Last Name is required')
      }
      else if (email === '') {
        throw new Error('Email is required')
      }
      else if (phoneNumber === '') {
        throw new Error('Phone Number is required')
      }
      else if (role === '') {
        throw new Error('Role is required')
      }
      else {
        const response = await makeRequest2(addNewUserURL, 'POST', {
          firstName, lastName, email, phoneNumber, role
        })
        if (response.success) {
          setUserDialog(false)
          toastify('Added New User Successfully', 'success')
        }
        else {
          throw new Error(response.error)
        }
      }


    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  const HandleDeleteUser = async (userId) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this user?");
      if (!confirmDelete) {
        return;
      }
      const response = await makeRequest2(deleteUserURL, 'GET', {});
      if (response.success) {
        toastify(response.data, 'success');
        fetchUsers()
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }

  const deleteUserTemplate = (rowData) => {

    if (userEmail !== rowData.email) {
      return (
        <Link
          to={'#'}
          className="avtar avtar-xs btn-link-secondary btn-pc-default"

          onClick={() => { HandleDeleteUser(rowData._id) }}
        >
          <i className="ti ti-trash f-18" style={{ color: 'red' }}></i>
        </Link>
      )
    }
    else {
      return (<>....</>)
    }




  }
  const getCurrentUser = async () => {
    try {
      const currentUser = await getItem('COREUSER')
      if (currentUser) {
        const email = currentUser.user.email
        setUserEmail(email)
      }

    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchSMSEmailSettings();
    fetchUsers()
    getCurrentUser()
  }, [])

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/settings"}>Settings</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">

                            <li className="nav-item">
                              <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                <i className="ti ti-users me-2"></i>Users
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                <i className="ti ti-bell me-2"></i>Email / SMS
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                <i className="ti ti-columns me-2"></i>Water Meter Settings
                              </a>
                            </li>


                          </ul>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className='card'>
                                <div className='card-header'>
                                  <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                      setUserDialog(true)
                                    }}><i className="ti ti-plus" />&nbsp;Add</button>
                                  </div>

                                </div>
                                <div className="card-body">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable value={users} emptyMessage="No users found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                      <Column field="fullName" sortable header="Fullname"></Column>
                                      <Column field="email" header="Email"></Column>
                                      <Column field="phoneNumber" header="Phone Number"></Column>
                                      <Column field="role" header="Role"></Column>
                                      <Column field="" header="Actions" body={deleteUserTemplate}></Column>

                                    </DataTable>
                                    <br />

                                  </div>
                                </div>


                              </div>

                            </div>

                          </div>
                        </div>


                        <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                          <div className='row'>
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Email</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="mb-3">
                                        <label className="form-label">From:</label>
                                        <input type="email" className="form-control" value={emailFrom} onChange={(e) => {
                                          setEmailFrom(e.target.value)
                                        }} />
                                      </div>
                                      <div className="mb-3">
                                        <label className="form-label">Port:</label>
                                        <input type="number" className="form-control" value={emailPort} onChange={(e) => {
                                          setEmailPort(e.target.value)
                                        }} />
                                      </div>
                                      <div className="mb-3">
                                        <label className="form-label">Host:</label>
                                        <input type="text" className="form-control" value={emailHost} onChange={(e) => {
                                          setEmailHost(e.target.value)
                                        }} />
                                      </div>
                                      <div className="mb-3">
                                        <label className="form-label">Auth User:</label>
                                        <input type="email" className="form-control" value={emailAuthUser} onChange={(e) => {
                                          setEmailAuthUser(e.target.value)
                                        }} />
                                      </div>
                                      <div className="mb-3">
                                        <label className="form-label">Auth Pass:</label>
                                        <input type="password" className="form-control" value={emailAuthPass} onChange={(e) => {
                                          setEmailAuthPass(e.target.value)
                                        }} />
                                      </div>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>SMS</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="mb-3">
                                        <label className="form-label">Provider</label>
                                        <select type="text" className="form-control" onChange={(e) => {
                                          const value = e.target.value;
                                          setProvider(value)
                                        }}>
                                          <option value="">-- Select --</option>
                                          <option value="Africastalking">Africastalking</option>
                                          <option value="Meliora">Meliora</option>
                                        </select>
                                      </div>
                                      {
                                        provider === 'Africastalking' &&
                                        <>
                                          <div className="mb-3">
                                            <label className="form-label">Sender Id:</label>
                                            <input type="text" className="form-control" value={senderId1} onChange={(e) => {
                                              setSenderId1(e.target.value)
                                            }} />
                                          </div>
                                          <div className="mb-3">
                                            <label className="form-label">Username:</label>
                                            <input type="text" className="form-control" value={username} onChange={(e) => {
                                              setUsername(e.target.value)
                                            }} />
                                          </div>
                                          <div className="mb-3">
                                            <label className="form-label">API Key:</label>
                                            <input type="text" className="form-control" value={apiKey1} onChange={(e) => {
                                              setAPIKey1(e.target.value)
                                            }} />
                                          </div>
                                        </>
                                      }
                                      {
                                        provider === 'Meliora' &&
                                        <>
                                          <div className="mb-3">
                                            <label className="form-label">Sender Id:</label>
                                            <input type="text" className="form-control" value={senderId2} onChange={(e) => {
                                              setSenderId2(e.target.value)
                                            }} />
                                          </div>
                                          <div className="mb-3">
                                            <label className="form-label">API Key:</label>
                                            <input type="text" className="form-control" value={apiKey2} onChange={(e) => {
                                              setAPIKey2(e.target.value)
                                            }} />
                                          </div>
                                        </>
                                      }


                                    </div>

                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="card-footer text-end btn-page">

                              <div className="btn btn-primary" onClick={HandleUpdateEmailSMSSettings}>Update</div>
                            </div>



                          </div>

                        </div>
                        <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-3">
                          <div className='row'>
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Manufacturer(s)</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-8">
                                      <label>Name:</label>
                                      <input className="form-control mt-2" placeholder='Enter Manufacturer Name' />

                                    </div>
                                    <div className="col-sm-4 pt-4">
                                      <button className="btn btn-primary">Submit</button>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Meter Size(s)</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-8">
                                      <label>Name:</label>
                                      <input className="form-control mt-2" placeholder='Enter Meter Size' />

                                    </div>
                                    <div className="col-sm-4 pt-4">
                                      <button className="btn btn-primary">Submit</button>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </div>
                           


                          </div>

                        </div>


                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>


          </div>

        </div>
      </div>
      <Dialog header={'Add New User'} visible={userDialog} style={{ width: '30vw' }} onHide={() => { setUserDialog(false) }}>
        <div className="row">
          <div className="col-md-12 pt-3">
            <div className="mb-3">
              <label className="form-label">First Name:</label>
              <input type="text" className="form-control" placeholder='Enter First Name' value={firstName} onChange={(e) => {
                const value = e.target.value;
                setFirstName(value)
              }} />
            </div>
            <div className="mb-3">
              <label className="form-label">Last Name:</label>
              <input type="text" className="form-control" placeholder='Enter Last Name' value={lastName} onChange={(e) => {
                const value = e.target.value;
                setLastName(value)
              }} />
            </div>
            <div className="mb-3">
              <label className="form-label">Email:</label>
              <input type="email" className="form-control" placeholder='Enter Email' value={email} onChange={(e) => {
                const value = e.target.value;
                setEmail(value)
              }} />
            </div>
            <div className="mb-3">
              <label className="form-label">Phone Number:</label>
              <input type="number" className="form-control" placeholder='Enter Phone Number' value={phoneNumber} onChange={(e) => {
                const value = e.target.value;
                setPhoneNumber(value)
              }} />
            </div>
            <div className="mb-3">
              <label className="form-label">Role:</label>
              <select type="text" className="form-control" onChange={(e) => {
                const value = e.target.value;
                setRole(value)
              }}>
                <option value="">-- Select --</option>
                <option value="admin">Administrator</option>
              </select>
            </div>
            <div className="mb-3" style={{ float: 'right' }}>
              <button className="btn btn-primary" onClick={HandelAddUser}>Submit</button>
            </div>
          </div>
        </div>
      </Dialog>

    </Layout>
  )
}


export default Setting